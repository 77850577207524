import React, { useState, useEffect } from "react";
import "./RatingNumpadComponent.scss";
import QuestionDetailsComponent from "./QuestionDetailsComponent";

const NumpadComponent = (props) => {
  let countRange = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const { question, answer } = props;
  const [value, setValue] = useState(answer.value);

  if (question.ratingScaleFromNum === 1) {
    countRange.shift();
  }
  const pattern =
    question.ratingScaleFromNum === 1
      ? "rating_numpad_row"
      : "rating_numpad_row pattern_full";

  useEffect(() => {
    props.setAnswer(props, value);
  }, [value]);

  return (
    <div>
      <div className="rating_numpad">
        <div className={pattern}>
          {/* loop range count */}
          {countRange.map((x, i) => {
            const dataCount = question.ratingScaleFromNum === 1 ? i + 1 : i;
            return (
              <div
                key={`key_${props.question.elementKey}_${dataCount}`}
                className="rating_numpad_col"
              >
                <div
                  className="rating_numpad_btn default2"
                  data-value={i}
                  is-deactive={
                    i === parseInt(value, 10) ? parseInt(value, 10) : ""
                  }
                  is-active={
                    i === parseInt(value, 10) ? parseInt(value, 10) : ""
                  }
                  onClick={(e) => {
                    // @ts-ignore
                    const scope = e.target.closest(".question.rating");
                    const dataValue = e.target.getAttribute("data-value");
                    const ratingBtn =
                      scope.querySelectorAll(".rating_numpad_btn");
                    ratingBtn.forEach((item) => {
                      let v = item.getAttribute("data-value");
                      if (v !== dataValue) {
                        item.setAttribute("is-deactive", "");
                      } else {
                        item.setAttribute("is-deactive", dataValue);
                      }
                    });
                    setValue(dataValue);
                  }}
                >
                  {dataCount}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const LabelComponent = (props) => {
  const { beginLabel, endLabel } = props;
  return (
    <div className="rating_numpad_label">
      <div className="rating_numpad_label_row">
        <div className="rating_numpad_label_col">
          <div className="nomood"></div>
          <div className="text_left">{beginLabel}</div>
        </div>
        <div className="rating_numpad_label_col">
          <div className="text_right">{endLabel}</div>
          <div className="hasmood"></div>
        </div>
      </div>
    </div>
  );
};

class RatingNumpadComponent extends React.Component {
  render() {
    const { question } = this.props;
    let beginLabel = question.npsQuestionContent.startLabelText;
    let endLabel = question.npsQuestionContent.endLabelText;
    return (
      <div id={`${question.elementKey}`} className={`question rating`}>
        <QuestionDetailsComponent {...this.props} />
        <div>
          <NumpadComponent {...this.props} />
          {beginLabel || endLabel ? (
            <LabelComponent beginLabel={beginLabel} endLabel={endLabel} />
          ) : null}
        </div>
      </div>
    );
  }
}

export default RatingNumpadComponent;
