import React from 'react';
import $ from 'jquery-slim';
import QuestionDetailsComponent from './QuestionDetailsComponent';
import { stripHtml } from 'string-strip-html';

class Opions extends React.Component {
  render() {
    return this.props.choices.map((p, i) => {
      return (
        <option key={`${p.elementKey + i}`} value={p.elementKey}>
          {stripHtml(p.choiceText).result}
        </option>
      );
    });
  }
}

class DropdownListComponent extends React.Component {
  componentDidUpdate() {
    const { answer } = this.props;
    const select = document.getElementById(
      `dropdownlist${this.props.question.elementKey}`,
    );
    if (answer.value) {
      $.each($(select).find('option'), function (i, v) {
        if ($(v).val() * 1 === 0) {
          $(v).remove();
        }
      });
      $(select).val(answer.value);
    } else {
      $(select).val(0);
    }
  }
  componentDidMount() {
    const { answer } = this.props;
    const { questionContent } = this.props.question;
    const select = document.getElementById(
      `dropdownlist${this.props.question.elementKey}`,
    );
    if (answer.value) {
      $(select).val(answer.value);
    } else {
      //wait for defualt value
      $(select).prepend(
        `<option style='display:none;' selected value='0'>${
          questionContent && !questionContent.helpText
            ? ''
            : questionContent.helpText
        }</option>`,
      );

      //  const { choices } = this.props.question;
      $(select).val(0);
    }
  }

  switchLanguages(publishKey, elementKey, targetValue) {
    let switchLangKey = '92BBA268-D3DA-406E-82E3-E32576CA2668';
    let engLangKey = '1EEBFC48-A1AF-4A09-858C-8A73CAFC6B0F';
    let thaiLangKey = '3D0ECB33-590B-45D7-BBBE-FE84FE1F820C';

    if (elementKey === switchLangKey) {
      if (targetValue === thaiLangKey) {
        let thaiLanguageID = 1;
        this.props.setLanguages(publishKey, thaiLanguageID);
      } else if (targetValue === engLangKey) {
        let engLanguageID = 2;
        this.props.setLanguages(publishKey, engLanguageID);
      }
    }
  }

  // on load set default value
  setDefaultValue() {
    const { choices } = this.props.question;
    for (let i = 0; i < choices.length; i++) {
      if (choices[i].selectChoiceDefault) {
        this.switchLanguages(
          this.props.publishKey,
          this.props.question.elementKey,
          choices[i].elementKey,
        );
        this.props.setAnswer(this.props, choices[i].elementKey);
        return choices[i].choiceText;
      }
    }
  }

  render() {
    const { choices } = this.props.question;
    const { answer } = this.props;
    return (
      <div
        id={`${this.props.question.elementKey}`}
        className={`question dropdown`}
      >
        <QuestionDetailsComponent {...this.props} />
        <div className="question_element">
          <select
            id={`dropdownlist${this.props.question.elementKey}`}
            defaultValue={answer.value ? answer.value : this.setDefaultValue()}
            onChange={(e) => {
              this.switchLanguages(
                this.props.publishKey,
                this.props.question.elementKey,
                e.target.value,
              );
              this.props.setAnswer(this.props, e.target.value);
            }}
          >
            <Opions choices={choices} />
          </select>
        </div>
      </div>
    );
  }
}

export default DropdownListComponent;
